import React from 'react';
import Container from "./components/Container";

function App() {
  return (
    <Container />
  );
}

export default App;
